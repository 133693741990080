import React, { useEffect, useState } from "react";
import { SiNetlify, SiReact, SiTailwindcss } from "react-icons/si";

export default function About() {
	const techs = [
		{ Icon: SiReact, color: "text-blue-500", duration: "duration-500" },
		{ Icon: SiTailwindcss, color: "text-blue-300", duration: "duration-700" },
		{ Icon: SiNetlify, color: "text-green-400", duration: "duration-1000" },
	];
	const [animate, setAnimate] = useState(false);
	useEffect(() => {
		setAnimate(true);
	}, []);
	return (
		<div>
			<h1 className="text-2xl text-yellow-200 animate-reveal-0.5">About me</h1>
			<p className="text-lg mb-3 animate-reveal-0.5">
				Hello! I'm Adarsh Kunwar, a full stack web developer and passionate computer application student. Whenever a spark of curiosity hits me, i build projects to sate it.
			</p>
			<h1 className="text-2xl text-yellow-200 animate-reveal-0.7">Why is this portfolio in terminal</h1>
			<p className="text-lg animate-reveal-0.7">
				Recently, I've had to use the terminal for some tasks, and as i used it more and more, it became ever so convenient. And before i knew it, i was a slave to it.
				<div></div>
				So, i made this portfolio in terminal fashion to pull you into the craze that i've fallen in.
				You're welcome
			</p>
			<h1 className="text-2xl text-yellow-200 animate-reveal-0.7">Brain killers</h1>
			<p className="text-lg animate-reveal-0.7">
				Rather than working on the same three projects everyone else seems to be working on 'todo app, ecommerce, and portfolio website', I prefer creating things that actually let me learn something new.
				<div></div>
				I call them brain killers, because creating them felt like i was killing all my rabing cells. But hey, it's worth it.
				<a
					href={''}
					target="_blank"
					rel="noreferrer"
					className="flex-1 underline text-blue-300 "
				>
					{"this website"}
				</a>
				&nbsp;
			</p>
			<h1 className="text-2xl text-yellow-200 animate-reveal-0.7">Let's Connect</h1>
			<p className="text-lg animate-reveal-0.7">
				Thank you for visiting my terminal. Feel free to explore and reach out if you have any questions or projects in mind. Let’s build something amazing together!
			</p>
		</div>
	);
}
